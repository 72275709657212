import React from "react";
import ForgetPasswordNavbar from "./ForgetPasswordNavbar";
import wrong from "../../Images/wrong.svg";
import { useNavigate } from "react-router-dom";

const ForgetPasswordError = () => {
  let navigate = useNavigate();
  const tryagain = () => {
    navigate("/forgetpassword");
  };
  return (
    <>
      <ForgetPasswordNavbar />
      <div className=" absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
        <div className="flext justify-center items-center flex-col">
          <img alt="right" src={wrong} className="block m-auto mb-8"></img>
          <h2 className=" font-medium text-5xl leading-[57px] text-center mb-3">
            Check your email
          </h2>
          <p className=" font-normal text-lg leading-7 text-center max-w-[394px]">
            Please, click the button below and try again
          </p>
          <button
            type="submit"
            onClick={tryagain}
            className="bg-softpeach mb-12 mt-8 signupbtn form_controler"
          >
            <p className=" py-4 rounded-[32px] font-medium text-base leading-[1.375rem] text-bluishgrey hover:bg-mediumturquoise hover:text-blackpearl">
              Try again
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordError;
