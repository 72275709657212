import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { typeOfPackageApi, typeOfPackages } from "../../api";
import { MultiStepContext } from "../../Context/StepContext";
import Loader from "../Loader/Loader";

function TypeOfPackageForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  // active step and logic for next and previous step
  const { setActiveStep } = useContext(MultiStepContext);
  useEffect(() => {
    setActiveStep(3);
  });

  //type of packages
  const [packageData, setPackageData] = useState([]);
  useQuery(["fetch-packages"], typeOfPackages, {
    onSuccess: (res) => setPackageData(res.data.data),
  });
  const IncNum = (index) => {
    packageData[index].currCount = packageData[index].currCount + 1;
    setPackageData([...packageData]);
  };
  const DecNum = (index) => {
    if (packageData[index].currCount >= 1) {
      packageData[index].currCount = packageData[index].currCount - 1;
      setPackageData([...packageData]);
    }
  };

  // react-query
  const { mutate, isLoading } = useMutation(
    (data) => typeOfPackageApi(id, data),
    {
      onSuccess: () => {
        navigate(`/pickup/${id}/step6`);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    let mapped = packageData?.map((iterator) => ({
      [iterator.field]: iterator.currCount,
    }));
    var newObject = Object.assign({}, ...mapped);
    mutate(newObject);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="pickupheading text-left mt-[2.75rem] mb-3">
        I want to send
      </h2>
      <p className="pickupsubheading mb-9">
        Pick type and amount of packages that you want to send
      </p>
      <div className=" py-[11px] px-[14px] bg-darkorange w-[332px] rounded-[37px] mb-6">
        <p className=" text-sm font-normal leading-4 text-white">
          Stoovo doesn’t provide packaging materials
        </p>
      </div>
      {packageData?.map((packages, index) => {
        return (
          <div
            key={index}
            className="flex justify-between items-center gap-4  bg-white rounded-[20px] p-4 mb-5 h-[176px]"
          >
            <img src={packages?.image} alt="box" />

            <div className="flex flex-col">
              <h3 className=" font-bold text-xl text-blackpearl">
                {packages?.key}
              </h3>
              <p className=" font-normal text-sm text-comet max-w-[264px]">
                {packages?.value}
              </p>
            </div>
            <div className="supportbtn w-[148px] flex justify-between">
              <button
                onClick={() => {
                  DecNum(index);
                  if (packages.currCount === 0) {
                    alert("You need atleast one order from the below !");
                  }
                }}
              >
                -
              </button>
              <span> {packages?.currCount}</span>

              <button
                onClick={() => {
                  IncNum(index);
                }}
              >
                +
              </button>
            </div>
          </div>
        );
      })}

      <div className="flex justify-between flex-col items-center gap-4  bg-white rounded-[20px] p-4 mb-5">
        <div className="flex justify-between w-full">
          <h2>5 Packages</h2>
          <p>Clear all</p>
        </div>
        <hr></hr>
        <div className="flex gap-[12px]">
          <button
            className="supportbtn navigatebtn btn2"
            onClick={() => {
              navigate(`/pickup/${id}/step4`);
            }}
          >
            Back
          </button>
          <button
            className="supportbtn navigatebtn btn2"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default TypeOfPackageForm;
