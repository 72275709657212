import { Navigate } from "react-router-dom";
import { getToken } from "./utils";

const withoutAuthentication = (ComposedComponent) => {
  const Component = (props) => {
    if (getToken()) {
      return <Navigate to="dashboard" />;
    }
    return <ComposedComponent {...props} />;
  };
  return <Component />;
};

export default withoutAuthentication;
