import Navbar from "../Navbar/Navbar";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import user from "../../Images/Avataruser.svg";
import icon1 from "../../Images/address.svg";
import icon2 from "../../Images/safty.svg";
import icon3 from "../../Images/order.svg";
import edit from "../../Images/edit.svg";
import exit from "../../Images/exit.svg";
import { removeToken } from "../../utils/utils";
import close from "../../Images/close.svg";
import useredit from "../../Images/useredit.svg";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateUserProfile } from "../../api";
import { useUser } from "../../Context/UserProvider";

function Profile() {
  const userData = useUser();

  let navigate = useNavigate();

  const logout = () => {
    removeToken();
    navigate("/login");
  };
  const [model, setModel] = useState(false);
  const conformationmodel = () => {
    setModel(true);
  };
  const closemodel = () => {
    setModel(false);
  };
  // model===true? document.body.classList.add("overlay"):document.body.classList.remove("overlay");

  const { mutate } = useMutation((data) => updateUserProfile(data), {
    onSuccess: (res) => {
      setModel(false);
    },
    onError: (err) => {
      console.log(err);
      alert("An error occured while submiting the form");
    },
  });

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: userData?.user?.email,
      name: userData?.user?.full_name,
    },
    validationSchema: yup.object({
      email: yup.string().email().required("Email is required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <>
      <Navbar />
      <button
        className="homebtn"
        type="submit"
        onClick={() => navigate(`/dashboard`)}
      >
        Home
      </button>
      <div className="container-custom model">
        <div className=" mb-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center gap-2 mb-[26px]">
              <img src={user} alt="user" />
              <div className="flex flex-col">
                <h2 className=" font-bold text-2xl left-4"> {values.name}</h2>
                <p className=" font-normal text-sm leading-4">{values.email}</p>
              </div>
            </div>
            <button
              className=" w-[115px] h-[34px] rounded-[28px] bg-softpeach"
              onClick={conformationmodel}
            >
              Edit Profile
            </button>
          </div>

          <div className="w-[640px] h-[1px] bg-softpeach mb-8"></div>
        </div>
        <div className="mb-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex justify-start items-center">
              <img src={icon1} className="mr-3" alt="box" />
              <div className="flex flex-col ">
                <p className=" text-sm font-normal text-comet">Address</p>
                <p className=" font-medium text-base text-blackpearl">
                  742 Acacia Ave, San Bruno, CA 94066
                </p>
              </div>
            </div>
            <img src={edit} alt="box" />
          </div>

          <div className="flex items-center justify-between mb-6">
            <div className="flex justify-start items-center">
              <img src={icon2} className="mr-3" alt="box" />
              <div className="flex flex-col ">
                <p className=" text-sm font-normal text-comet">Safety</p>
                <p className=" font-medium text-base text-blackpearl">
                  Change password
                </p>
              </div>
            </div>
            <img src={edit} alt="box" />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex justify-start items-center">
              <img src={icon3} className="mr-3" alt="box" />
              <div className="flex flex-col ">
                <p className=" font-medium text-base text-blackpearl">
                  Orders history
                </p>
              </div>
            </div>
            <img src={edit} alt="box" />
          </div>
        </div>
        <div className="w-[640px] h-[1px] bg-softpeach mb-8 mt-[47px]"></div>

        <button className=" flex items-center gap-3" onClick={logout}>
          <img alt="exit" src={exit} />
          <p className="font-medium text-base text-blackpearl">Logout</p>
        </button>
      </div>
      {model === true ? (
        <div className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 p-7 rounded-[28px] bg-white mb-6 opacity-100 z-40 w-[384px]">
          <div className="flex justify-between mb-8">
            <h2 className=" font-bold text-2xl leading-8">Personal details</h2>
            <button onClick={closemodel}>
              <img alt="close" src={close} />
            </button>
          </div>
          <img alt="useredit" className="block m-auto mb-8" src={useredit} />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="labelname">Full Name</label>
              <input
                name="name"
                type="text"
                placeholder="name "
                value={values.name}
                onChange={handleChange}
                className={"form-control"}
              />
            </div>
            <div className="form-group">
              <label className="labelname">Email</label>
              <input
                name="email"
                type="email"
                placeholder="Enter email"
                value={values.email}
                onChange={handleChange}
                className={"form-control"}
              />
            </div>

            <button
              type="submit"
              className="bg-softpeach   signupbtn form_controler"
            >
              <p className=" py-4 rounded-[32px] font-medium text-base leading-[1.375rem] text-bluishgrey hover:bg-mediumturquoise ">
                Save
              </p>
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Profile;
