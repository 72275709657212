import { useQuery } from "react-query";
import React, { createContext, useContext } from "react";
import { getUser } from "../api";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(
    ["fetch-user-history"],
    () => getUser(),

    {
      refetchOnWindowFocus: false,
    }
  );

  const value = { user: response?.data, isLoading, isError };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};
