import { useQuery } from "react-query";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { getOrderCheckout } from "../api";

export const CheckoutContext = createContext(null);

export const CheckoutProvider = ({ children }) => {
  const { id } = useParams();

  const {
    data: response,
    isLoading,
    isError,
    refetch,
  } = useQuery(["fetch-checkout-history", id], () => getOrderCheckout(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const value = {
    isError,
    isLoading,
    checkoutData: response?.data?.data,
    refetch,
  };
  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutData = () => {
  return useContext(CheckoutContext);
};
