import React from "react";
import ForgetPasswordNavbar from "./ForgetPasswordNavbar";
import right from "../../Images/right.svg";

const ForgetPasswordSuccess = () => {
  return (
    <>
      <ForgetPasswordNavbar />
      <div className=" absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
        <div className="flext justify-center items-center flex-col">
          <img alt="right" src={right} className="block m-auto mb-8"></img>
          <h2 className=" font-medium text-5xl leading-[57px] text-center mb-3">
            Check your email
          </h2>
          <p className=" font-normal text-lg leading-7 text-center max-w-[394px]">
            We’ve sent you an email with a link to reset your password
          </p>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordSuccess;
