import React from "react";
import logo from "../../Images/vector.svg";

const ForgetPasswordNavbar = () => {
  return (
    <>
      <div className="flex justify-between mt-7 items-center mb-[3.375rem]">
        <img className="ml-[4.375rem] " src={logo} alt="" />
      </div>
    </>
  );
};

export default ForgetPasswordNavbar;
