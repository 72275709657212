import React, { useState, createContext } from "react";

export const MultiStepContext = createContext();

export const StepContext = ({ children }) => {
  const [activestep, setActiveStep] = useState(1);

  return (
    <>
      <MultiStepContext.Provider value={{ activestep, setActiveStep }}>
        {children}
      </MultiStepContext.Provider>
    </>
  );
};
