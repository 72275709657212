import React from "react";
import logo from "../../Images/vector.svg";
import user from "../../Images/usernavbar.svg";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between mt-7 items-center mb-[3.375rem]">
        <img className="ml-[4.375rem] " src={logo} alt="" />
        <div className="mr-[4.375rem] flex items-center gap-[1.125rem]">
          <button className="supportbtn">Support</button>
          <button
            onClick={() => {
              navigate("/profile");
            }}
          >
            <img className=" " src={user} alt="" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Navbar;
