import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import user from "../../Images/Icon1.svg";
import work from "../../Images/Icon2.svg";
import { deliveryTypeForm } from "../../api";
import * as yup from "yup";
import { useFormik } from "formik";
import { MultiStepContext } from "../../Context/StepContext";
import { useCheckoutData } from "../../Context/CheckoutProvider";
import Loader from "../Loader/Loader";

const DeliveryAddressTypeForm = () => {
  let { id } = useParams();

  //navigate
  const navigate = useNavigate();

  //toogle button logic
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked2, setIsClicked2] = useState(true);

  const myNewFn = () => {
    setIsClicked(true);
    setIsClicked2(false);
  };
  const myNewFnsecond = () => {
    setIsClicked(false);
    setIsClicked2(true);
  };

  const onContinuClick = () => {
    const data = isClicked2 ? "doorstep" : "handoff";
    const datas = { typeOfMethod: data, ...formik.values };
    mutate(datas);
  };

  // active step and logic for next and previous step
  const { setActiveStep } = useContext(MultiStepContext);

  useEffect(() => {
    setActiveStep(2);
  });

  // handling the back button render with form values
  const { checkoutData, isLoading } = useCheckoutData();
  const orderData = checkoutData?.delivery_address;

  // react useMutation
  const { mutate } = useMutation((data) => deliveryTypeForm(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step5`);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // formik
  const validationSchema = yup.object().shape({
    additional_info: yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      additional_info: orderData?.additional_info || "",
    },
    enableReinitialize: true,
    validationSchema,
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <h2 className="pickupheading text-left mt-[2.75rem] mb-3">
        Delivery method
      </h2>
      <p className="pickupsubheading mb-9">
        Tell us where to deliver your packages
      </p>

      <div className="p-7 rounded-[28px] bg-white pb-0 ">
        <div className="flex justify-center gap-4 mb-8">
          <button
            onClick={myNewFn}
            className={clsx("btn  w-[286px]", {
              "test-class": isClicked,
            })}
          >
            <div className="accountbtn">
              <img className="w-full h-full" src={user} alt="hamburger" />
              <h5>Direct handoff</h5>
              <p>Meet your Stoover at the door to ensure a secure handoff</p>
            </div>
          </button>
          <button
            onClick={myNewFnsecond}
            className={clsx("btn  w-[286px]", {
              "test-class": isClicked2,
            })}
          >
            <div className="accountbtn ">
              <img className="w-full h-full" src={work} alt="hamburger" />
              <h5>Leave on doorstep</h5>
              <p>
                Place items outside your door in advance of your pickup time
              </p>
            </div>
          </button>
        </div>

        <form onSubmit={formik.handleSubmit} className="form">
          <div className="form-group">
            <label className="labelname">
              Additional iformation for Stoover (Optional)
            </label>

            <input
              name="additional_info"
              type="additional_info"
              className={
                "form-control" +
                (formik.errors.additional_info && formik.touched.additional_info
                  ? " is-invalid"
                  : "")
              }
              onChange={formik.handleChange}
              value={formik.values.additional_info}
            />
            <div className="invalid-feedback">
              {formik.errors.additional_info && formik.touched.additional_info
                ? formik.errors.additional_info
                : null}
            </div>
          </div>
        </form>
      </div>
      <div className=" mt-7 flex gap-3">
        <button
          className="buttongroup btn2"
          onClick={() => {
            navigate(`/pickup/${id}/step3`);
          }}
        >
          Back
        </button>
        <button className="buttongroup btn2" onClick={onContinuClick}>
          Next
        </button>
      </div>
    </>
  );
};

export default DeliveryAddressTypeForm;
