import { useFormik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { forgotPassword } from "../../api";
import logo from "../../Images/vector.svg";
import heroImage from "../../Images/info.png";

const ForgetPassword = () => {
  let navigate = useNavigate();
  // react-query-post
  const { mutate } = useMutation((data) => forgotPassword(data), {
    onSuccess: (res) => {
      navigate("/forgot-password-success");
    },
    onError: (err) => {
      navigate("/forgot-password-error");
    },
  });

  // useFormik
  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().required("Email is required"),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });
  return (
    <div className="flex justify-between">
      <div className="form_control">
        <div className="max-w-[30rem] w-full block m-auto pt-[30%]">
          <img className=" " src={logo} alt="" />
          <h1 className=" font-bold text-[1.938rem] leading-[2.563rem] mt-[2.563rem] mb-3">
            Forgot your password
          </h1>
          <p className=" font-normal text-base leading-5 mb-8">
            No worries, we’ll send you a link to update your password
          </p>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                name="email"
                type="email"
                placeholder="Your email"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
                onChange={handleChange}
                value={values.email}
              />
              <div className="invalid-feedback">
                {errors.email && touched.email ? errors.email : null}
              </div>
            </div>

            <button
              type="submit"
              className="bg-softpeach mb-12 mt-8 signupbtn form_controler"
            >
              <p className=" py-4 rounded-[32px] font-medium text-base leading-[1.375rem] text-bluishgrey hover:bg-mediumturquoise  hover:text-blackpearl">
                Reset password
              </p>
            </button>
          </form>
        </div>
      </div>
      <div className="w-1/2 h-screen">
        <img className="w-full h-full" src={heroImage} alt="hamburger" />
      </div>
    </div>
  );
};

export default ForgetPassword;
