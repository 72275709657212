import React, { useContext } from "react";
import { MultiStepContext } from "../../Context/StepContext";

function Step() {
  const { activestep } = useContext(MultiStepContext);

  const steps = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="flex justify-center items-center steepers gap-[83px]">
        {steps.map((step, index) => {
          return (
            <div
              key={index}
              className={`step ${step < activestep && "pre_activestep"}  ${
                step === activestep && "activestep"
              }  ${step === 1 && "hidden32"}`}
            >
              {step}{" "}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Step;
