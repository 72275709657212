import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Step from "../Steeper/Step";
import { StepContext } from "../../Context/StepContext";
import { CheckoutProvider } from "../../Context/CheckoutProvider";

function PickupAddress() {
  return (
    <>
      <StepContext>
        <Navbar />
        <Step />
        <div className="container-custom">
          <CheckoutProvider>
            <Outlet />
          </CheckoutProvider>
        </div>
      </StepContext>
    </>
  );
}

export default PickupAddress;
