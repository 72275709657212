import React from "react";
import Navbar from "../Navbar/Navbar";
import map from "../../Images/Map-tracking.png";
import user from "../../Images/Rectangle 8221.png";
import edit from "../../Images/edit.svg";
import {
  deleteOrder,
  getOrderCheckout,
  packageDatacall,
  paymentStatus,
} from "../../api";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import icon1 from "../../Images/Icon.svg";
import icon3 from "../../Images/Icon (2).svg";
import Loader from "../Loader/Loader";

function OrderTracking() {
  const { id } = useParams();

  const { data: packageData } = useQuery(["dsbshhsdhvs"], packageDatacall, {
    onSuccess: (res) => {
      // console.log(res)
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(["fetch-checkout-history"], () => getOrderCheckout(id), {
    enabled: !!id,
  });
  const value = {
    isError,
    isLoading,
    checkoutData: response?.data?.data,
  };
  const values = value.checkoutData;

  const packagevalue = packageData?.data?.data;

  const getImage = (value) => {
    const record = packagevalue.find((item) => item.field === value) || {};
    return record.image;
  };
  const packageTypeData = values?.pickup_type;
  const boxes = Object.keys(packageTypeData || {})
    .filter((value) => value !== "_id")
    .map((value) => {
      return {
        name: value,
        value: packageTypeData[value],
        image: getImage(value),
      };
    })
    .filter((value) => value.value !== 0);

  const { data: packageAmount } = useQuery(
    ["sasdffgggffsdfggfedsw"],
    () => paymentStatus(id),
    {
      onSuccess: (res) => {},
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const navigate = useNavigate();

  const { mutate } = useMutation((data) => deleteOrder(id, data), {
    onSuccess: (res) => {
      navigate("/dashboard");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <button
        className="homebtn"
        type="submit"
        onClick={() => navigate(`/dashboard`)}
      >
        Home
      </button>
      <div className="container-custom">
        <h2 className="pickupheading text-left mt-[2.75rem] mb-3">
          Stoover will arrive soon
        </h2>
        <p className=" font-normal text-xl leading-7 mb-9">
          Stoover <span className=" font-bold"> Andrew </span>is heading to you
        </p>
        <img src={map} className="mb-9" alt="map" />

        <div className="p-7 rounded-[28px] bg-white mb-6">
          <div className="flex justify-start items-center gap-2">
            <img src={user} alt="map" />
            <div className="flex flex-col">
              <p className=" font-normal text-sm leading-4">
                Your Stoovo courier
              </p>
              <h2 className=" font-bold text-2xl left-4">Andrew</h2>
            </div>
          </div>
        </div>
        <div className="p-7 rounded-[28px] bg-white mb-6">
          <h2 className="cardheading mb-8">Delivery information</h2>

          <div className="flex items-center justify-between mb-6">
            <div className="flex justify-start items-center">
              <img src={icon1} className="mr-3" alt="box" />
              <div className="flex flex-col ">
                <p className=" text-sm font-normal text-comet">
                  {values.delivery_address.address}
                </p>
                <p className=" font-medium text-base text-blackpearl">
                  {values.delivery_address.name} |
                  {values.delivery_address.phone_num}
                </p>
              </div>
            </div>
            <img src={edit} alt="box" />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex justify-start items-center">
              <img src={icon3} className="mr-3" alt="box" />
              <div className="flex flex-col ">
                <p className=" text-sm font-normal text-comet">
                  Delivery method
                </p>
                <p className=" font-medium text-base text-blackpearl">
                  {values.delivery_method.typeOfMethod}
                </p>
              </div>
            </div>
            <img src={edit} alt="box" />
          </div>
        </div>
        <div className="p-7 rounded-[28px] bg-white mb-6">
          <h2 className="cardheading mb-8">3 Packages</h2>
          {boxes?.map((packages, index) => (
            <div className="flex justify-between items-center mb-3" key={index}>
              <div className="flex justify-start items-center gap-3">
                <img
                  src={packages?.image}
                  className="w-[68px] h-[68px]"
                  alt="box"
                />
                <h6 className=" text-lg font-bold text-blackpearl">
                  {packages?.name}
                </h6>
                <p className=" text-lg font-normal text-blackpearl">
                  {" "}
                  x{packages?.value}
                </p>
              </div>
              <img src={edit} alt="box" />
            </div>
          ))}
        </div>

        <button className="canbtn" onClick={() => mutate(id)} type="submit">
          Cancel order
        </button>
      </div>
    </>
  );
}

export default OrderTracking;
