import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

// getToken
export const getToken = () => {
  return Cookies.get("token");
};

// removeToken
export const removeToken = () => {
  return Cookies.remove("token");
};

export const RequireAuth = ({ children }) => {
  if (!getToken()) {
    return <Navigate to={"/login"} />;
  }
  return children;
};
