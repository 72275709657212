import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { pickupTime } from "../../api";

import DatePicker from "react-datepicker";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { MultiStepContext } from "../../Context/StepContext";
import Loader from "../Loader/Loader";

function PickUpDateAndTime() {
  const navigate = useNavigate();
  let { id } = useParams();

  //toogle button logic
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked2, setIsClicked2] = useState(false);

  const myNewFn = () => {
    setIsClicked(true);
    setIsClicked2(false);
  };
  const myNewFnsecond = () => {
    setIsClicked(false);
    setIsClicked2(true);
  };

  //steeper
  const { setActiveStep } = useContext(MultiStepContext);
  useEffect(() => {
    setActiveStep(4);
  });

  //date and time
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = useState(["10:00", "11:00"]);
  const dateOnChange = (stat) => {
    setStartDate(stat);
  };
  const timeOnChange = (stat) => {
    setValue(stat);
  };

  // react-query
  const { mutate, isLoading } = useMutation((data) => pickupTime(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step7`);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const delivery_terms = isClicked2 ? "ASAP" : "fixed";
    const start_date = moment(startDate).format("YYYY-MM-DD") + " " + value[0];
    const end_date = moment(startDate).format("YYYY-MM-DD") + " " + value[1];
    mutate({ delivery_terms, start_date, end_date });
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <h2 className="pickupheading text-left mt-[2.75rem] mb-3">
        Pickup date and time
      </h2>
      <p className="pickupsubheading mb-9">
        Tell us where to deliver your packages
      </p>
      <div className="p-7 rounded-[28px] bg-white mb-6">
        <h2 className="cardheading">Delivery terms</h2>
        <div className="flex justify-start gap-4  mt-6">
          <button
            onClick={myNewFn}
            className={clsx("btn w-[194px]", {
              "test-class": isClicked,
            })}
          >
            <div className="accountbtn">
              <h5>$3</h5>
              <p>Scheduled pickup with fixed time set by Stoovo</p>
            </div>
          </button>
          <button
            onClick={myNewFnsecond}
            className={clsx("btn w-[194px]", {
              "test-class": isClicked2,
            })}
          >
            <div className="accountbtn">
              <h5>$7</h5>
              <p>ASAP pickup time</p>
            </div>
          </button>
        </div>
      </div>

      <form noValidate onSubmit={handleSubmit}>
        <div className="p-7 rounded-[28px] bg-white mb-6">
          <h2 className="cardheading">Pickup date and time</h2>

          <div>
            <DatePicker selected={startDate} onChange={dateOnChange} />
            <TimeRangePicker
              format={"hh:mm: a"}
              disableClock
              onChange={timeOnChange}
              value={value}
              minTime="10:00:00"
              maxTime="19:00:00"
            />
          </div>
        </div>

        <button
          className="buttongroup btn2"
          onClick={() => {
            navigate(`/pickup/${id}/step5`);
          }}
        >
          Back
        </button>
        <button className="buttongroup btn2">Next</button>
      </form>
    </>
  );
}

export default PickUpDateAndTime;
