import React, { useContext, useEffect, useState } from "react";
import icon1 from "../../../Images/Icon.svg";
import icon2 from "../../../Images/Icon (1).svg";
import icon3 from "../../../Images/Icon (2).svg";
import edit from "../../../Images/edit.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getOrderCheckout,
  packageDatacall,
  paymentStatus,
  paymentStatus2,
} from "../../../api";
import { MultiStepContext } from "../../../Context/StepContext";
import Loader from "../../Loader/Loader";

function Checkout() {
  const { id } = useParams();
  const navigate = useNavigate();

  //steeper
  const { setActiveStep } = useContext(MultiStepContext);
  useEffect(() => {
    setActiveStep(5);
  });

  //tip button logic
  const [alignment, setAlignment] = useState(0);
  const first = () => {
    alignment === 1 ? setAlignment(0) : setAlignment(1);
  };
  const second = () => {
    alignment === 2 ? setAlignment(0) : setAlignment(2);
  };
  const third = () => {
    alignment === 3 ? setAlignment(0) : setAlignment(3);
  };

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(["fetch-checkout-history"], () => getOrderCheckout(id), {
    enabled: !!id,
  });
  const value = {
    isError,
    isLoading,
    checkoutData: response?.data?.data,
  };
  const values = value.checkoutData;

  const { data: packageData } = useQuery(
    ["dsbshhsdhvs"],
    () => packageDatacall(),
    {
      onSuccess: (res) => {
        // console.log(res)
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const packagevalue = packageData?.data?.data;
  const getImage = (value) => {
    const record = packagevalue?.find((item) => item.field === value) || {};
    return record.image;
  };
  const packageTypeData = values?.pickup_type;
  const boxes = Object.keys(packageTypeData || {})
    .filter((value) => value !== "_id")
    .map((value) => {
      return {
        name: value,
        value: packageTypeData[value],
        image: getImage(value),
      };
    })
    .filter((value) => value.value !== 0);

  const { data: packageAmount } = useQuery(
    ["sasdffgggffsdfggfedsw"],
    () => paymentStatus(id),
    {
      onSuccess: (res) => {},
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // react-query
  const { mutate } = useMutation((data) => paymentStatus2(id, data), {
    onSuccess: (res) => {
      navigate(`/order-tracking/${id}`);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(totalFee);
  };

  const totalFeeDevNum = packageAmount?.data?.amount;
  const totalFee = totalFeeDevNum + alignment;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="pickupheading text-left mt-[2.75rem] mb-3">Checkout</h2>

      <div className="p-7 rounded-[28px] bg-white mb-6">
        <h2 className="cardheading mb-8">Pickup information</h2>

        <div className="flex items-center justify-between mb-6">
          <div className="flex justify-start items-center">
            <img src={icon1} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">
                {values?.pickup_address?.address}
              </p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.pickup_address?.name} |
                {values?.pickup_address?.phone_num}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>

        <div className="flex items-center justify-between mb-6">
          <div className="flex justify-start items-center">
            <img src={icon2} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">
                Pickup date and time
              </p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.pickup_date?.start_date}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex justify-start items-center">
            <img src={icon3} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">Pickup method</p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.pickup_method?.typeOfMethod}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>
      </div>

      <div className="p-7 rounded-[28px] bg-white mb-6">
        <h2 className="cardheading mb-8">Delivery information</h2>

        <div className="flex items-center justify-between mb-6">
          <div className="flex justify-start items-center">
            <img src={icon1} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">
                {values?.delivery_address?.address}
              </p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.delivery_address?.name} |
                {values?.delivery_address?.phone_num}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>

        <div className="flex items-center justify-between mb-6">
          <div className="flex justify-start items-center">
            <img src={icon2} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">
                Delivery date and time
              </p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.pickup_date?.end_date}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex justify-start items-center">
            <img src={icon3} className="mr-3" alt="box" />
            <div className="flex flex-col ">
              <p className=" text-sm font-normal text-comet">Delivery method</p>
              <p className=" font-medium text-base text-blackpearl">
                {values?.delivery_method?.typeOfMethod}
              </p>
            </div>
          </div>
          <img src={edit} alt="box" />
        </div>
      </div>

      <div className="p-7 rounded-[28px] bg-white mb-6">
        <h2 className="cardheading mb-8">3 Packages</h2>
        {boxes?.map((packages, index) => (
          <div className="flex justify-between items-center mb-3" key={index}>
            <div className="flex justify-start items-center gap-3">
              <img
                src={packages?.image}
                className="w-[68px] h-[68px]"
                alt="box"
              />
              <h6 className=" text-lg font-bold text-blackpearl">
                {packages?.name}
              </h6>
              <p className=" text-lg font-normal text-blackpearl">
                {" "}
                x{packages?.value}
              </p>
            </div>
            <img src={edit} alt="box" />
          </div>
        ))}
      </div>
      <div className="p-7 rounded-[28px] bg-white mb-6 w-[304px] fixed top-[124px] right-16">
        <h2 className="cardheading mb-8">Order summary</h2>
        <p className=" font-medium text-xl leading-7 mb-7">3 packages</p>
        <div className="flex justify-between items-center mb-3">
          <p className=" font-normal text-lg leading-7 text-bluishgrey">
            Delivery Fee
          </p>
          <p className=" font-normal text-lg text-blacktype">
            ${totalFeeDevNum}
          </p>
        </div>
        <p className=" font-normal text-lg leading-7 text-bluishgrey  ">
          Stoover tip
        </p>
        <div className=" mt-3 mb-6 flex gap-2">
          <button className="btntip" onClick={first}>
            $1
          </button>
          <button className="btntip" onClick={second}>
            $2
          </button>
          <button className="btntip" onClick={third}>
            $3
          </button>
          <button className="btntip">other</button>
        </div>
        <div className="hrline"></div>
        <div className="flex justify-between items-center mt-6">
          <p className=" font-medium text-base leading-7  text-blacktype ">
            Total
          </p>
          <p className=" font-bold text-2xl text-blacktype">${totalFee}</p>
        </div>
        <form noValidate onSubmit={handleSubmit}>
          <button className="buttongroup btn2 !w-full mt-7">Pay</button>
        </form>
      </div>
    </>
  );
}

export default Checkout;
