import Cookies from "js-cookie";
import api from "./api";

export const addUser = (data) => {
  return api.post(`/signup`, data).then((response) => {
    if (response.data.token) {
      Cookies.set("token", response.data.token);
    }
  });
};

export const addTypeOfAccount = (data) => {
  return api.post(`/type-of-acc`, data);
};

export const PickupDataForm = (data) => {
  return api.post(`/pickup-add`, data);
};

export const pickupTypeForm = (id, datas) => {
  return api.post(`/pickup-method/${id}`, datas);
};

export const deliveryAddForm = (id, data) => {
  return api.post(`/delivery-add/${id}`, data);
};

export const deliveryTypeForm = (id, datas) => {
  return api.post(`/delivery-method/${id}`, datas);
};

export const getOrderCheckout = (id) => {
  return api.get(`/checkout/${id}`);
};

export const typeOfPackageApi = (id, data) => {
  return api.post(`/pickup-type/${id}`, data);
};

export const typeOfPackages = () => {
  return api.get(`/type-of-packages`);
};

export const pickupTime = (id, data) => {
  return api.post(`/pickup-date/${id}`, data);
};

export const paymentStatus = (id) => {
  return api.get(`/payment/${id}`);
};

export const packageDatacall = () => {
  return api.get(`/type-of-packages`);
};

export const paymentStatus2 = (id, data) => {
  return api.post(`/payment-status/${id}`, data);
};

export const deleteOrder = (id, data) => {
  return api.post(`/cancel-order/${id}`);
};
export const getUser = () => {
  return api.get(`/me`);
};
export const authLogin = (data) => {
  return api.post(`/login`, data).then((response) => {
    if (response?.data?.token) {
      Cookies.set("token", response?.data?.token);
    }
  });
};
export const forgotPassword = (data) => {
  return api.post(`/forgot-pass`, data);
};

export const updateUserProfile = (data) => {
  return api.post(`/update-profile`, data);
};
