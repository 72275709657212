import { Navigate } from "react-router-dom";
import { getToken } from "./utils";

const withAuthentication = (ComposedComponent) => {
  const Component = (props) => {
    if (getToken()) {
      return <ComposedComponent {...props} />;
    }
    return <Navigate to="signup" />;
  };

  return <Component />;
};

export default withAuthentication;
