import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
// import Signup from "../Component/Signup/Signup";
// import AccountType from "../Component/AccoutType/AccountType";
// import Dashboard from "../Component/Dashboard/Dashboard";
import PickupAddress from "../Component/Pickup Address/PickupAddress";
import OrderTracking from "../Component/OrderTracking/OrderTracking";
import Error404 from "../Component/404/Error404";
import PickupAddressForm from "../Component/Forms/PickupAddressForm";
import PickupAddressTypeForm from "../Component/Forms/PickupAddressTypeForm";
import DeliveryAddressForm from "../Component/Forms/DeliveryAddressForm";
import DeliveryAddressTypeForm from "../Component/Forms/DeliveryAddressTypeForm";
import TypeOfPackageForm from "../Component/Forms/TypeOfPackageForm";
import PickUpDateAndTime from "../Component/Forms/PickUpDateAndTime";
import Checkout from "../Component/Forms/Checkout/Checkout";
import withoutAuthentication from "../utils/withoutAuthentication";
import { UserProvider } from "../Context/UserProvider";
import { StepContext } from "../Context/StepContext";
import { CheckoutProvider } from "../Context/CheckoutProvider";
import withAuthentication from "../utils/withAuthentication";
import Loader from "../Component/Loader/Loader";
import ForgetPassword from "../Component/ForgetPassword/ForgetPassword";
import ForgetPasswordSuccess from "../Component/ForgetPassword/ForgetPasswordSuccess";
import ForgetPasswordError from "../Component/ForgetPassword/ForgetPasswordError";
import Profile from "../Component/Profile/Profile";
// import Login from "../Component/Login/Login";

const Dashboard = React.lazy(() =>
  import("../Component/Dashboard/Dashboard.js")
);
const Signup = React.lazy(() => import("../Component/Signup/Signup.js"));
const AccountType = React.lazy(() =>
  import("../Component/AccoutType/AccountType.js")
);
const Login = React.lazy(() => import("../Component/Login/Login.js"));

const Router = () => {
  const UnAuthenticated = () => <Outlet />;

  const Authenticated = () => {
    return (
      <>
        <UserProvider>
          <StepContext>
            <CheckoutProvider>
              <Outlet />
            </CheckoutProvider>
          </StepContext>
        </UserProvider>
      </>
    );
  };
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/*" element={withAuthentication(Authenticated)}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="signup" element={<Signup />} />
            <Route path="type-of-account" element={<AccountType />} />
            <Route path="pickup/*" element={<PickupAddress />}>
              <Route path="step1" element={<PickupAddressForm />}></Route>
              <Route path=":id/step1" element={<PickupAddressForm />}></Route>
              <Route
                path=":id/step2"
                element={<PickupAddressTypeForm />}
              ></Route>
              <Route path=":id/step3" element={<DeliveryAddressForm />}></Route>
              <Route
                path=":id/step4"
                element={<DeliveryAddressTypeForm />}
              ></Route>
              <Route path=":id/step5" element={<TypeOfPackageForm />}></Route>
              <Route path=":id/step6" element={<PickUpDateAndTime />}></Route>
              <Route path=":id/step7" element={<Checkout />}></Route>
            </Route>
            <Route path="order-tracking/:id" element={<OrderTracking />} />
            <Route path="profile" element={<Profile />} />

            <Route path="*" element={<Error404 />}></Route>
          </Route>

          <Route path="/" element={withoutAuthentication(UnAuthenticated)}>
            <Route index element={<Navigate to={"signup"} />} />
            <Route path="login" element={<Login />} />
            <Route path="forgetpassword" element={<ForgetPassword/>} />
            <Route path="forgot-password-success" element={<ForgetPasswordSuccess/>} />
            <Route path="forgot-password-error" element={<ForgetPasswordError/>} />



            <Route path="signup" element={<Signup />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
