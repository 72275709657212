import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import styles from "../../css/pickupaddressform.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { deliveryAddForm } from "../../api";
import { MultiStepContext } from "../../Context/StepContext";
import { useCheckoutData } from "../../Context/CheckoutProvider";
import Loader from "../Loader/Loader";

function DeliveryAddressForm() {
  const navigate = useNavigate();
  let { id } = useParams();

  // active step and logic for next and previous step
  const { setActiveStep } = useContext(MultiStepContext);
  useEffect(() => {
    setActiveStep(2);
  });

  // handling the back button render with form values
  const { checkoutData, isLoading, refetch } = useCheckoutData();
  const orderData = checkoutData?.delivery_address;

  // react-query-post
  const { mutate } = useMutation((data) => deliveryAddForm(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step4`);
      refetch();
    },
    onError: (err) => {
      console.log(err);
      alert("An error occured while submiting the form");
    },
  });

  //formik
  const validationSchema = yup.object().shape({
    name: yup.string().max(30).required("Full Name is required "),
    city: yup.string().required(),
    phone_num: yup.string().required("Phone number is required"),
    address: yup.string().required(),
    apt_office: yup.string().required("Apartment/Office is Required "),
    floor: yup.string().required("Floor Number is required"),
    additional_details: yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      name: orderData?.name || "",
      phone_num: orderData?.phone_num || "",
      city: orderData?.city || "",
      apt_office: orderData?.apt_office || "",
      floor: orderData?.floor || "",
      additional_details: orderData?.additional_details || "",
      address: orderData?.address || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (data, { resetForm }) => {
      resetForm({ values: "" });

      mutate(formik.values);
    },
  });
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="pickupheading text-left mt-[2.75rem] ">
        Delivery address
      </h2>
      <p className="pickupsubheading mb-9">
        Tell us where to deliver your packages
      </p>

      <form onSubmit={formik.handleSubmit} className="form">
        <div className={clsx(styles.card)}>
          <div className=" rounded-[28px] bg-white pb-0 ">
            <h3 className="cardheading">Contact details</h3>

            <div className=" grid grid-cols-2 gap-3 mt-5">
              <div className="form-group">
                <label className="labelname">Full Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Name Surname"
                  className={
                    "form-control !mb-0" +
                    (formik.errors.name && formik.touched.name
                      ? " is-invalid"
                      : "")
                  }
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <div className="invalid-feedback">
                  {formik.errors.name && formik.touched.name
                    ? formik.errors.name
                    : null}
                </div>
              </div>

              <div className="form-group">
                <label className="labelname">Phone Number</label>

                <input
                  name="phone_num"
                  type="phone_num"
                  className={
                    "form-control !mb-0" +
                    (formik.errors.phone_num && formik.touched.phone_num
                      ? " is-invalid"
                      : "")
                  }
                  onChange={formik.handleChange}
                  value={formik.values.phone_num}
                />
                <div className="invalid-feedback">
                  {formik.errors.phone_num && formik.touched.phone_num
                    ? formik.errors.phone_num
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.card, "mt-7")}>
          <h3 className="cardheading">Address</h3>

          <div className=" grid grid-cols-2 gap-3 mt-5">
            <div className="form-group">
              <label className="labelname">City</label>

              <input
                name="city"
                type="city"
                className={
                  "form-control" +
                  (formik.errors.city && formik.touched.city
                    ? " is-invalid"
                    : "")
                }
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              <div className="invalid-feedback">
                {formik.errors.city && formik.touched.city
                  ? formik.errors.city
                  : null}
              </div>
            </div>
            <div className="form-group">
              <label className="labelname">Address</label>

              <input
                name="address"
                type="address"
                className={
                  "form-control" +
                  (formik.errors.address && formik.touched.address
                    ? " is-invalid"
                    : "")
                }
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              <div className="invalid-feedback">
                {formik.errors.address && formik.touched.address
                  ? formik.errors.address
                  : null}
              </div>
            </div>

            <div className="form-group">
              <label className="labelname">Apt/Office</label>

              <input
                name="apt_office"
                type="apt_office"
                className={
                  "form-control" +
                  (formik.errors.apt_office && formik.touched.apt_office
                    ? " is-invalid"
                    : "")
                }
                onChange={formik.handleChange}
                value={formik.values.apt_office}
              />
              <div className="invalid-feedback">
                {formik.errors.apt_office && formik.touched.apt_office
                  ? formik.errors.apt_office
                  : null}
              </div>
            </div>

            <div className="form-group">
              <label className="labelname">Floor</label>

              <input
                name="floor"
                type="floor"
                className={
                  "form-control" +
                  (formik.errors.floor && formik.touched.floor
                    ? " is-invalid"
                    : "")
                }
                onChange={formik.handleChange}
                value={formik.values.floor}
              />
              <div className="invalid-feedback">
                {formik.errors.floor && formik.touched.floor
                  ? formik.errors.floor
                  : null}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="labelname">
              Additional iformation for Stoover (Optional)
            </label>

            <input
              name="additional_details"
              type="additional_details"
              className={
                "form-control additional_info !mb-0" +
                (formik.errors.additional_details &&
                formik.touched.additional_details
                  ? " is-invalid"
                  : "")
              }
              onChange={formik.handleChange}
              value={formik.values.additional_details}
            />
            <div className="invalid-feedback">
              {formik.errors.additional_details &&
              formik.touched.additional_details
                ? formik.errors.additional_details
                : null}
            </div>
          </div>
        </div>
        <div className=" mt-7 flex gap-3">
          <button
            className="buttongroup btn2"
            onClick={() => {
              navigate(`/pickup/${id}/step2`);
            }}
          >
            Back
          </button>
          <button className="buttongroup btn2">Next</button>
        </div>
      </form>
    </>
  );
}

export default DeliveryAddressForm;
